import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Home from './Home';
import ImageList from './ImageList';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <main className="main-content">
          <Container>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/admin/images" element={<ImageList />} />
            </Routes>
          </Container>
        </main>
        <footer className="py-3 bg-dark text-white text-center">
          <Container>
            <p className="mb-0">
              This app was created by Ricardo Schmidt using Amazon Bedrock (Claude Sonnet).
            </p>
            <p className="mb-0 text-muted">
              For learning purposes only.
            </p>
          </Container>
        </footer>
      </div>
    </Router>
  );
};

export default App;