import React, { useState, useRef } from 'react';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { Container, Row, Col, Form, Button, Alert, Spinner, Card } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import he from 'he';
import './Home.css'; // We'll create this file for custom styles

const Home = () => {
  const [file, setFile] = useState(null);
  const [analysis, setAnalysis] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);
  const [language, setLanguage] = useState('en'); // Default to English

  const validateFile = (file) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    return file && validTypes.includes(file.type);
  };

  const generateUniqueFileName = (originalFileName) => {
    const dateStr = new Date().toISOString().replace(/[-:]/g, '').split('.')[0];
    const randomStr = Math.random().toString(36).substring(2, 15);
    const fileExtension = originalFileName.split('.').pop();
    return `${dateStr}_${randomStr}.${fileExtension}`;
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (validateFile(selectedFile)) {
      setFile(selectedFile);
      setError('');
    } else {
      setFile(null);
      setError('Please select a valid image file (PNG, GIF, or JPEG).');
      if (fileInputRef.current) fileInputRef.current.value = '';
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Please select a file first!');
      return;
    }

    setIsLoading(true);
    setAnalysis('');
    setError('');

    try {
      const s3Client = new S3Client({
        region: process.env.REACT_APP_AWS_REGION,
        credentials: fromCognitoIdentityPool({
          identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
          clientConfig: { region: process.env.REACT_APP_AWS_REGION }
        }),
      });

      const uniqueFileName = generateUniqueFileName(file.name);

      const uploadParams = {
        Bucket: process.env.REACT_APP_UPLOAD_BUCKET_NAME,
        Key: uniqueFileName,
        Body: file
      };

      await s3Client.send(new PutObjectCommand(uploadParams));
      console.log("File uploaded successfully");

      const apiUrl = `${process.env.REACT_APP_API_URL}?bucket=${uploadParams.Bucket}&key=${uploadParams.Key}&language=${language}`;
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error('Failed to analyze image');
      const result = await response.json();
      
      const decodedHtml = he.decode(result);
      const sanitizedHtml = DOMPurify.sanitize(decodedHtml);
      setAnalysis(sanitizedHtml);
    } catch (error) {
      console.error("Error:", error);
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Row className="justify-content-center">
      <Col md={8} lg={6}>
        <Card className="shadow-lg">
          <Card.Header className="bg-dark text-white">
            <h1 className="text-center mb-0">Welcome to Plantify!</h1>
          </Card.Header>
          <Card.Body>
                <Alert variant="info" className="mb-4">
                  <p>
                Welcome to <b>Plantify</b>, your personal plant identification and care assistant! Whether you're a gardening enthusiast or just curious about the plants around you, Plantify is here to help you discover and learn about the wonderful world of flora.
              </p>
              <h5>How It Works:</h5>
              <ol>
                <li><strong>Upload a Photo:</strong> Click the "Upload Photo" button to take or select an image of a plant or flower.</li>
                <li><strong>Identify:</strong> Our advanced image recognition technology will identify the plant species.</li>
                <li><strong>Learn:</strong> Receive detailed information about the plant, including its name, species, and history.</li>
                <li><strong>Care Tips:</strong> Get expert advice on how to care for your plant, including watering, sunlight, and soil tips.</li>
              </ol>
              <p>Enjoy discovering and caring for your plants with Plantify!</p>
                </Alert>
                <Form>
                  <Form.Group controlId="formFile" className="mb-4">
                    <Form.Label>Upload a plant image (PNG, GIF, or JPEG):</Form.Label>
                    <div className="custom-file-input">
                      <Form.Control 
                        type="file" 
                        onChange={handleFileChange} 
                        ref={fileInputRef}
                        accept="image/png, image/gif, image/jpeg"
                        className="d-none"
                      />
                      <Button 
                        variant="outline-light" 
                        onClick={() => fileInputRef.current.click()}
                        className="w-100"
                      >
                        Choose File
                      </Button>
                      {file && <p className="mt-2 mb-0">Selected file: {file.name}</p>}
                    </div>
                  </Form.Group>
                  <Form.Group controlId="languageSelect" className="mb-4">
                <Form.Label>Select language for analysis instructions:</Form.Label>
                <Form.Select 
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option value="en">English</option>
                  <option value="pt">Portuguese</option>
                </Form.Select>
              </Form.Group>
                  {error && <Alert variant="danger">{error}</Alert>}
                  <Button 
                    variant="analyze" 
                    onClick={handleUpload} 
                    disabled={isLoading || !file}
                    className="w-100"
                  >
                    {isLoading ? 
                      <>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        <span className="ms-2">Analysing...</span>
                      </> : 
                      'Upload photo and Analyse'
                    }
                  </Button>
                </Form>
              </Card.Body>
        </Card>
        <div style={{ marginTop: '40px', marginBottom: '40px' }}></div>
        {analysis && (
          <Card className="shadow-lg mb-4">
            <Card.Header className="bg-dark text-white">
              <h2 className="mb-0">Analysis Results</h2>
            </Card.Header>
            <Card.Body className="card-body-analysis">
              <div dangerouslySetInnerHTML={{ __html: analysis }} />
            </Card.Body>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default Home;