import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Alert, Spinner } from 'react-bootstrap';

const ImageList = () => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_IMAGE);
        if (!response.ok) throw new Error('Failed to fetch images');
        const data = await response.json();
        setImages(data || []);
      } catch (err) {
        console.error("Error fetching images:", err);
        setError('Failed to fetch images. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, []);

  if (isLoading) {
    return (
      <Container className="my-5 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Uploaded Images</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Row>
        {images.length > 0 ? (
          images.map((image) => (
            <Col key={image.Key} xs={12} sm={6} md={4} lg={3} className="mb-4">
              <Card>
                <Card.Img 
                  variant="top" 
                  src={image.URL}
                  style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                />
                <Card.Body>
                  <Card.Title>{image.Key.split('_').pop()}</Card.Title>
                  <Card.Text>
                    Uploaded on: {new Date(image.LastModified).toLocaleString()}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <Col>
            <Alert variant="info">No images uploaded yet.</Alert>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default ImageList;